<script>
import router from "../router";

import AliceLogoMark from "../components/shared/AliceLogoMark";
import PasswordVisibilityToggle from "../components/Login/PasswordVisibilityToggle.vue";
import LineDividerWithText from '../components/shared/LineDividerWithText.vue';
import ButtonGoogle from "../components/Login/ButtonGoogle.vue";
import PopUpCardModal from "../components/shared/PopUpCardModal.vue";
import CustomGoogleSignInButton from "../components/shared/session/CustomGoogleSignInButton.vue";
import { mapActions } from "vuex";

import { registerUser } from "../services/session";
import { useAuthWithGoogle } from "../composables/useAuthWithGoogle";

export default {
  name: 'SignUp',
  props: {},
  components: {
    AliceLogoMark,
    LineDividerWithText,
    PasswordVisibilityToggle,
    ButtonGoogle,
    PopUpCardModal,
    CustomGoogleSignInButton
  },
  data() {
    return {
      email: null,
      fullname: null,
      password: null,
      loading: false,
      passwordIndication: "Create a password with at least 8 characters.",
      signUpClicked: false,
      emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      showPassword: false,
      textMsgError: '',
      isPopupActive: false,
      duplicateEmailMessage: "This email address already exists, please 'Sign in' using the link below.",
      showDuplicateEmailMessage: false,
    }
  },
  computed: {
    isFormValid() {
      return this.isValidPassword && this.isValidEmail && this.isFullnameValid;
    },
    isValidPassword() {
      return this.password?.length >= 8;
    },
    isValidEmail() {
      return this.emailRegex.test(this.email);
    },
    isFullnameValid() {
      if (this.fullname && this.fullname.trim().split(' ').length === 2) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["setUserSessionAction"]),
    handleGoogleButtonClick(data) {
      const { loginWithGoogle } = useAuthWithGoogle();

      loginWithGoogle(data);
    },
    closePopUp() {
      this.$refs.modalError.hide();
    },
    navigateToLogin() {
      router.push({ name: 'login' });
    },
    async submitSignUp() {
      this.signUpClicked = true;
      if (!this.isFormValid) return;
      this.loading = true;
      try {
        const res_data = await registerUser({
          email: this.email,
          password: this.password,
          name: this.fullname,
        });
        if (res_data.error === false) {
          this.loading = false;
          this.showDuplicateEmailMessage = false;
          localStorage.setItem('email', this.email);
          router.push({ name: 'verify' });
        } else {
          this.loading = false;
          if (res_data.message) {
            this.showDuplicateEmailMessage = true;
          } else {
            this.textMsgError = 'An error has occurred.';
            this.$refs.modalError.showModal();
          }
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    resetErrors() {
      this.signUpClicked = false;
      this.showDuplicateEmailMessage = false;
    },
    handleInput() {
      this.resetErrors();
    },
  },
}
</script>

<template>
  <div>
    <b-container class="h-100 mr-auto ml-auto justify-center">
      <b-row align-v="center" class="h-100">
        <b-container>
          <b-row align-h="center" class="login-container">
            <b-row class="mr-0 ml-0">
              <b-col align-self="center">
                <b-container class="login">
                  <main id="login">
                    <section>
                      <router-link to="/login">
                        <AliceLogoMark />
                      </router-link>
                      <h1 class="login-title">
                        Sign up to turn your data into knowledge
                      </h1>

                      <b-overlay :show="loading" rounded="sm" style="color: var(--color-purple)">
                        <form @submit="submitSignUp" v-on:keyup.enter="submitSignUp" method="post">
                          <p class="check-email-indication color-error-login" v-if="showDuplicateEmailMessage">
                            {{ duplicateEmailMessage }}
                          </p>
                          <div class="sign-up-container">
                            <!-- Email input -->
                            <div class="input-container">
                              <input type="text" class="login-email" placeholder="Your work email" v-model="email"
                                autocomplete="off" @input="handleInput" />
                              <p v-if="!isValidEmail && signUpClicked" class="check-email-indication color-error-login">
                                Something doesn’t look right. Please check the email address and try again.
                              </p>
                            </div>

                            <!-- Full name input -->
                            <div class="input-container">
                              <input type="text" class="fullname-text" placeholder="Your full name" v-model="fullname"
                                autocomplete="off" @input="handleInput" />
                              <p v-if="!isFullnameValid && signUpClicked"
                                class="check-email-indication color-error-login">
                                Please write your first and last name here.
                              </p>
                            </div>

                            <!-- Password input -->
                            <div class="input-container">
                              <input id="password" name="password" :type="showPassword ? 'text' : 'password'"
                                :class="['login-text', !showPassword ? 'space-letter-pass' : '']"
                                placeholder="Your password" v-model="password" autocomplete="new-password"
                                @input="handleInput" />
                            </div>
                          </div>

                          <input :disabled="loading" @click="submitSignUp" type="button" class="sign-up"
                            value="Sign up" />
                        </form>
                      </b-overlay>

                      <!-- HIDE/PASSWORD (EYE)  -->
                      <div class="eye-container" v-on:click="showPassword = !showPassword">
                        <PasswordVisibilityToggle :showPassword="showPassword" />
                      </div>

                      <!-- SIGN UP BUTTON  -->
                      <!-- FIXME: "It is done in this way (although not optimal) because the first input gets misconfigured." -->
                      <p :class="[!isValidPassword && signUpClicked ? 'color-error-login' : '', 'create-password-text']">
                        {{ passwordIndication }}
                      </p>
                      <h3 class="signing-up-text">
                        By signing up you agree to our
                        <span>Terms of Use</span> and
                        <span>Privacy Policy</span>
                      </h3>

                      <!-- OR -->
                      <LineDividerWithText />

                      <!-- Customizable google button -->
                      <CustomGoogleSignInButton />

                      <ButtonGoogle @on_google_login="handleGoogleButtonClick" />

                      <div class="rememberme-area">
                        <span class="remember-me">
                          <h3>Already have an account?</h3>
                          <button class="login-view-togglebtn" @click='navigateToLogin'>
                            <span class="purple-underline">Sign In</span>
                          </button>
                        </span>
                      </div>
                      <PopUpCardModal ref="modalError" :isPopupActive="isPopupActive" :closePopUp="closePopUp"
                        titleCard="SignUp" :textMsg="textMsgError" :isOk="true" />
                    </section>
                  </main>
                </b-container>
              </b-col>
            </b-row>
          </b-row>
        </b-container>
      </b-row>
    </b-container>
    <footer class="footer-bobble-graph">
      <img src="../assets/Loginbackground.svg" alt="bobble-graph" width="100%" height="100%">
    </footer>
  </div>
</template>

<style>
@import "../assets/styles/views/Login.css";
</style>