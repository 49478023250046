<template>
    <Eye v-if="showPassword" />
    <EyeSlash v-else />
</template>

<script>
import Eye from './Eye.vue';
import EyeSlash from './EyeSlash.vue';

export default {
    name: 'PasswordVisibilityToggle',
    props: {
        showPassword: {
            type: Boolean,
        }
    },
    components: { Eye, EyeSlash }
}
</script>

<style></style>